import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { InfinitySpin } from 'react-loader-spinner';
import styles from "./App.module.css";

function processImage(file) {
  return new Promise(async (resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = img.width / 2;
      const height = img.height / 2;

      canvas.width = width;
      canvas.height = height;

      const images = [];
      for (let y = 0; y < 2; y++) {
        for (let x = 0; x < 2; x++) {
          ctx.clearRect(0, 0, width, height);
          ctx.drawImage(img, -x * width, -y * height);
          const timestamp = Date.now();
          const dataURL = canvas.toDataURL("image/png");
          images.push({ name: `mj-img-${timestamp}-${y * 2 + x + 1}.png`, data: dataURL });
        }
      }

      resolve({ [file.name.split('.')[0]]: images });
    };
  });
}

function App() {
  const [loading, setLoading] = React.useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    setLoading(true);
    const zip = new JSZip();

    const imagePromises = acceptedFiles.map(processImage);
    const processedImages = await Promise.all(imagePromises);

    for (const imageSet of processedImages) {
      const [imageSetName, images] = Object.entries(imageSet)[0];
      const folder = zip.folder(imageSetName);

      for (const imageData of images) {
        folder.file(imageData.name, imageData.data.split(",")[1], { base64: true });
      }
    }

    const folderName = `mj-breakup-images-${Date.now()}`;
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, folderName);

    setLoading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*" });

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Midjourney Breakup</h1>
      <p className={styles.subheader}>Easily split Midjourney-generated 4x4 images into individual components with SWS Midjourney Breakup. This user-friendly tool processes images client-side, enabling quick downloads of separated images in a convenient zip format.</p>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        <p className={styles.instructions}>
          Drag and drop one or more 4x4 images, or click to select files
        </p>
      </div>
      {loading && (
        <div className={styles.overlay}>
          <div className={styles.loader}>
            <InfinitySpin width='200' color="#b06ab3" />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
